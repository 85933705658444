<template>
    <div>
        <!-- Back to top -->
        <!-- <a href="#" @click="scrollToTop" v-show="showTopButton" id="back-to-top" class="fixed  text-lg rounded-full z-10 bottom-5 end-5 h-9 w-9 text-center bg-violet-600 text-white leading-9"><i class="uil uil-arrow-up"></i></a> -->

        <a href="#" @click="scrollToTop" v-show="showTopButton" class="back-to-top rounded-pill fs-5"><i
            data-feather="arrow-up" class="fea icon-sm icons align-middle"></i></a>
        <!-- Back to top -->

    </div>
</template>

<script>
export default {
    name: "switcherVue",
    data() {
        return {
            showTopButton: false
        }
    },


    created() {
        window.addEventListener('scroll', this.handleScroll);
    },
    unmounted() {
        window.removeEventListener('scroll', this.handleScroll);
    },

    methods: {
        handleScroll() {
            if (
                document.body.scrollTop >= 50 ||
                document.documentElement.scrollTop >= 50
            ) {
                this.showTopButton = true
            } else {
                this.showTopButton = false
            }
        },


        scrollToTop() {
            window.scrollTo(0, 0);
        }
    },

}
</script>

<style lang="scss" scoped></style>