import {createWebHistory, createRouter} from "vue-router";

const routes = [
    {
        path: "/",
        name: "Index",
        component: () => import("@/pages/index-startup.vue"),
    },
    // {
    //     path: "/lawyer-list",
    //     name: "LawyerList",
    //     component: () => import("@/pages/lawyer-list.vue"),
    // },
    // {
    //     path: "/call",
    //     name: "CallComponent",
    //     component: () => import("@/pages/call.vue"),
    // },
    {
        path: "/blog-list",
        name: "BlogList",
        component: () => import("@/pages/blog-list.vue"),
    },
    {
        path: "/blog/:slug",
        name: "BlogDetails",
        component: () => import("@/pages/blog-details.vue"),
    },
];


const router = createRouter({
    history: createWebHistory(),
    routes,
});

export default router;