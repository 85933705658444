<template>
    <div>
        <router-view></router-view>
        <Switcher/>
    </div>
</template>

<script>
import Switcher from './components/Switcher.vue';


export default {
    name: 'App',
    components: {
        Switcher
    }
}
</script>

<style></style>
