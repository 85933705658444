import {createApp} from 'vue'
import App from './App.vue'
import 'bootstrap/dist/css/bootstrap.css'
import bootsratp from 'bootstrap/dist/js/bootstrap.bundle.js'
import "@/assets/scss/style.scss"
import router from './router'
import "@/assets/css/swiper.min.css"
import "@/assets/css/materialdesignicons.min.css"
import {VueTypedJs} from 'vue-typed-js'
import VueEasyLightbox from 'vue-easy-lightbox'

// setup axios defaults
import axios from 'axios'


axios.defaults.baseURL = 'https://app.lawyer.perfectoblog.com/api'
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.put['Content-Type'] = 'application/json'
axios.defaults.headers.delete['Content-Type'] = 'application/json'
axios.defaults.headers.get['Content-Type'] = 'application/json'
axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token')

const app = createApp(App)
app.use(VueEasyLightbox)
app.use(VueTypedJs)
app.use(router)
app.use(bootsratp)
app.mount('#app')
